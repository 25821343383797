var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "700" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              {
                staticClass: "d-flex justify-space-between",
                staticStyle: { width: "100%" }
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-center primary--text" },
                  [
                    _c("v-icon", { staticClass: "mr-1" }, [_vm._v("info")]),
                    _vm._v(" Invitation Error ")
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        _vm.dialog = false
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("clear")])],
                  1
                )
              ],
              1
            )
          ]),
          _c("v-spacer"),
          _c("v-divider"),
          _c("v-card-text", { staticClass: "mt-3" }, [
            _vm.errorType === _vm.DISTRIBUTED_SNAPSHOTS_FETCHING_ERROR
              ? _c("div", { staticClass: "d-flex flex-column" }, [
                  _vm._v(
                    " Your invitations haven't been sent because an error has occurred while fetching the latest distributed material. "
                  ),
                  _c("span", { staticClass: "mt-3" }, [
                    _vm._v(
                      " You can try to fetch and invite again or invite the users to empty instances and distribute material later."
                    )
                  ])
                ])
              : _vm.errorType ===
                _vm.emailValidationErrorTypes.INVALID_EMAILS_ERROR
              ? _c("div", { staticClass: "d-flex flex-column" }, [
                  _vm.isSignUpDialog === true
                    ? _c("span", [
                        _vm._v(
                          "Your sign up request hasn't been sent because the following email might be invalid:"
                        )
                      ])
                    : _c("span", [
                        _vm._v(
                          "Your invitations haven't been sent because the following emails might be invalid:"
                        )
                      ]),
                  _c(
                    "ul",
                    { staticClass: "mt-3" },
                    _vm._l(_vm.invalidEmails, function(email, index) {
                      return _c("li", { key: index }, [
                        _c(
                          "span",
                          { staticClass: "font-weight-bold caption" },
                          [_vm._v(_vm._s(email.email))]
                        )
                      ])
                    }),
                    0
                  )
                ])
              : _vm.errorType ===
                _vm.emailValidationErrorTypes.EMAIL_VALIDATION_BACKEND_ERROR
              ? _c("div", [
                  _vm._v(
                    " Our backend server could not validate the list of emails you provided. "
                  )
                ])
              : _vm._e()
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.errorType ===
              _vm.emailValidationErrorTypes.INVALID_EMAILS_ERROR
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [
                      _vm.isSignUpDialog === true
                        ? _c("span", [_vm._v("review email")])
                        : _c("span", [_vm._v("review emails")])
                    ]
                  )
                : _vm.errorType ===
                  _vm.emailValidationErrorTypes.EMAIL_VALIDATION_BACKEND_ERROR
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary", text: "" },
                      on: { click: _vm.validateAgain }
                    },
                    [_vm._v("validate again")]
                  )
                : _vm.errorType === _vm.DISTRIBUTED_SNAPSHOTS_FETCHING_ERROR
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary", text: "" },
                      on: { click: _vm.fetchSnapshotsAndInvite }
                    },
                    [_vm._v("fetch and invite again")]
                  )
                : _vm._e(),
              _vm.errorType === _vm.DISTRIBUTED_SNAPSHOTS_FETCHING_ERROR
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "error", text: "" },
                      on: { click: _vm.inviteWithoutFetchingDistributed }
                    },
                    [_vm._v(" invite to empty instances ")]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { color: "error", text: "" },
                      on: { click: _vm.inviteAnyway }
                    },
                    [
                      _vm.isSignUpDialog === true
                        ? _c("span", [_vm._v("request signup anyway")])
                        : _c("span", [_vm._v("invite anyway")])
                    ]
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }